import * as React from "react";
import * as Auth from "oauth2";
import classnames from "classnames";
import * as App from "global-state";
import * as Api from "../services/api";
import * as Router from "react-router-dom";
import * as Query from "react-query";
import * as Broker from "message-broker";
import { link } from "common/es/link";
import AdaptiveScreen from "common/es/adaptive-screen";
import BookIcon from "@valraiso-esf/esf-icons/es/book";
import SchoolHatIcon from "@valraiso-esf/esf-icons/es/school-hat";
import InfoIcon from "@valraiso-esf/esf-icons/es/info";
import MenuCard from "@valraiso-esf/esf-ui/es/menucard";
import Badge from "@valraiso-esf/esf-ui/es/badge";
import CampaignIcon from "@valraiso-esf/esf-icons/es/campaign";
import BoltIcon from "@valraiso-esf/esf-icons/es/bolt";
import LandscapeIcon from "@valraiso-esf/esf-icons/es/landscape";
import E911AvatarIcon from "@valraiso-esf/esf-icons/es/e911-avatar";
import TodayIcon from "@valraiso-esf/esf-icons/es/today";
import Button from "@valraiso-esf/esf-ui/es/button";
import Toast from "@valraiso-esf/esf-ui/es/toast";
import Snackbar from "@valraiso-esf/esf-ui/es/snackbar";
import Notification from "common/es/notification/notification";
import NotificationApp from "common/es/notification/notification-app";
import * as NotificationPermission from "common/es/notification/notification-app-permission";

import * as Hooks from "hooks";

import css from "./home-new-shortcuts.module.css";

export default function Home() {
  const [state] = App.useGlobalState();
  const navigate = Router.useNavigate();
  const { user, signed, signin } = Auth.useAuth();
  const [toast, setToast] = React.useState<boolean | undefined>(undefined);
  const ejectToast = Toast.eject(setToast);
  const { notification } = state.permissions;
  const [notificationPermission, setNotificationPermission] =
    React.useState<NotificationPermission.Permission>(notification);

  const { status, loading } = Hooks.useEnqueteStatus();
  const ecole = state.ecole?.code;
  const { data } = Query.useQuery(
    ["navigation-menu", state.ecole?.code, user],
    () => Api.fetchMenu(ecole)
  );

  const queryNumeroUrgence = Query.useQuery(
    ["numero-urgence", state.ecole?.code],
    () => {
      if (ecole !== undefined) {
        return Api.fetchEmergencyNumber(ecole);
      }
    }
  );
  const numeroUrgence = queryNumeroUrgence.data?.number;

  const hasActualites = data?.navigation.some(
    (item) => item.path === "/actualites"
  );
  const hasAccidents = data?.navigation.some(
    (item) =>
      (item.path !== undefined && item.path.startsWith("/accidents")) ||
      (item.children &&
        item.children.some(
          (child) =>
            child.path !== undefined && child.path.startsWith("/accidents")
        ))
  );
  const hasTracabilite = data?.navigation.some(
    (item) =>
      (item.path !== undefined && item.path.startsWith("/tracabilite")) ||
      (item.children &&
        item.children.some(
          (child) =>
            child.path !== undefined && child.path.startsWith("/tracabilite")
        ))
  );
  const hasAvalanche = data?.navigation.some(
    (item) =>
      (item.path !== undefined && item.path.startsWith("/avalanche")) ||
      (item.children &&
        item.children.some(
          (child) =>
            child.path !== undefined && child.path.startsWith("/avalanche")
        ))
  );
  const hasSecours = numeroUrgence !== undefined;

  React.useEffect(() => {
    if (Broker.isNativeApp()) {
      Broker.sendToNative({
        type: "permission",
        payload: { type: "notification" },
      });
    }
  }, []);

  // Native :
  // Permet de charger l'acceptation des notifications lorsque l'utilisateur
  // ne s'est pas encore prononcé.
  // Cela permet de garder monté le composant <NotificationApp />,
  // même après l'interaction de l'utilisateur, afin que le composant puisse terminer
  // le traitement de l'abonnement au notif.
  React.useEffect(() => {
    if (
      notificationPermission.canAskAgain === undefined &&
      notificationPermission.status === undefined &&
      notification.status &&
      notification.canAskAgain
    ) {
      setNotificationPermission(notification);
    }
  }, [notification, notificationPermission, user?.login]);

  const handleSignin = (e: React.MouseEvent) => {
    e.preventDefault();
    signin();
  };

  const showToast = () => {
    ejectToast(true);
  };

  const renderSignedDesktop = () => {
    return (
      <>
        {hasActualites && (
          <Router.Link to={`/actualites`} className={css.link}>
            <MenuCard icon={CampaignIcon} title="Actualités" active>
              Soyez au courant des actualités de votre école, du syndicat et du
              proshop
            </MenuCard>
          </Router.Link>
        )}
        {collectPlannings(data?.navigation)
          .filter((planning) => isWebPlanningPath(planning.path))
          .map((planning) =>
            planning.disabled ? (
              <div key={planning.path} onClick={showToast} className={css.link}>
                {renderPlanning(planning)}
              </div>
            ) : (
              renderPlanning(planning)
            )
          )}
        {hasAccidents && (
          <Router.Link to={`/accidents`} className={css.link}>
            <MenuCard icon={BoltIcon} title="Accidents" active>
              Déclarez un accident ou consultez vos déclarations
            </MenuCard>
          </Router.Link>
        )}
        {hasTracabilite && (
          <Router.Link to={`/tracabilite`} className={css.link}>
            <MenuCard icon={LandscapeIcon} title="Traçabilité" active>
              Préparez vos sorties en hors-piste
            </MenuCard>
          </Router.Link>
        )}
        {renderProshops()}
        {hasTracabilite && (
          <Router.Link to={`/esf-academy`} className={css.link}>
            <MenuCard icon={SchoolHatIcon} title="esf Academy" active>
              Inscrivez-vous à des formations de l'esf Academy
            </MenuCard>
          </Router.Link>
        )}
      </>
    );
  };
  const renderSignedMobile = () => {
    return (
      <>
        {collectPlannings(data?.navigation).map((planning) =>
          planning.disabled ? (
            <div key={planning.path} onClick={showToast} className={css.link}>
              {renderPlanning(planning)}
            </div>
          ) : (
            renderPlanning(planning)
          )
        )}
        {hasAccidents && (
          <Router.Link to={`/accidents`} className={css.link}>
            <MenuCard icon={BoltIcon} title="Accidents" active>
              Déclarez un accident ou consultez vos déclarations
            </MenuCard>
          </Router.Link>
        )}
        <Router.Link
          to={`/annuaire-esf${state.ecole ? `/${state.ecole.code}` : ""}`}
          className={css.link}
        >
          <MenuCard icon={BookIcon} title="Annuaire" active>
            Consulter la fiche et l'annuaire des moniteurs{" "}
            {state.ecole ? (
              <>
                de <b>{state.ecole.name}</b>
              </>
            ) : (
              <>
                de toutes les <b>esf</b>
              </>
            )}
          </MenuCard>
        </Router.Link>
        {hasAvalanche && (
          <Router.Link to={`/avalanche`} className={css.link}>
            <MenuCard icon={LandscapeIcon} title="Risque avalanche" active>
              Consulter le bulletin risque avalanche du jour
            </MenuCard>
          </Router.Link>
        )}
      </>
    );
  };

  const renderPlanning = (planning: NavigationNode) => (
    <MenuCard
      key={planning.path}
      icon={TodayIcon}
      title={planning.label}
      onClick={() => {
        if (planning.path !== undefined) link(navigate, planning.path, true);
      }}
      active
      disabled={planning.disabled}
      className={css.link}
    >
      Accéder directement à votre planning
    </MenuCard>
  );
  const renderProshops = () => {
    const proshops = collectProshops(data?.navigation);

    return proshops.map((proshop) => {
      return (
        <MenuCard
          key={proshop.path}
          icon={TodayIcon}
          title={proshop.label}
          onClick={() => {
            if (proshop.path !== undefined) link(navigate, proshop.path, true);
          }}
          active
          disabled={proshop.disabled}
          className={css.link}
        >
          Consulter le site du proshop
        </MenuCard>
      );
    });
  };

  const renderUnsigned = () => (
    <>
      <Router.Link to="/annuaire-esf" className={css.link}>
        <MenuCard icon={BookIcon} title="Annuaire écoles" active>
          Consulter l'annuaire de toutes les <b>esf</b>
        </MenuCard>
      </Router.Link>
    </>
  );

  const displayNotification =
    Broker.isWebApp() &&
    "Notification" in window &&
    window?.Notification?.permission === "default";

  const displayExpoNotification =
    user?.login === "ABU" &&
    Broker.isNativeApp() &&
    NotificationPermission.isUndetermined(notificationPermission);

  const classesLinkUrgences = classnames(css.link, css.urgences);

  return (
    <AdaptiveScreen title="Carnet Rouge" className={css.screen}>
      {data && [
        <AdaptiveScreen.Common key={"common-infos"}>
          {signed && (displayNotification || displayExpoNotification) && (
            <div className={css.notification}>
              <span className={css.notificationTitle}>Notifications</span>
              {displayNotification && <Notification />}
              {displayExpoNotification && <NotificationApp />}
              <div className={css.notificationDescription}>
                Les ESF et le SNMSF utilisent le système de notifications du
                Carnet Rouge pour communiquer avec vous, que ce soit pour des
                informations générales mais également en cas de perturbations
                sur le système.
              </div>
            </div>
          )}
          {signed &&
            user?.roles.includes("MONITEUR") &&
            !loading &&
            status !== "answered" &&
            new Date() >= new Date("2024-10-01 00:00:00") && (
              <Snackbar
                left={<InfoIcon variant="filled" />}
                classNames={{
                  main: classnames(css.snackbar, css.reminderEnquete),
                  children: css.children,
                }}
              >
                <div className={css.columnText}>
                  <div className={css.enqueteTitle}>
                    Vous n'avez pas encore répondu à l'enquête
                  </div>
                  <div>
                    Enquête sur l’activité professionnelle et qualification des
                    moniteurs
                  </div>
                </div>
                <Snackbar.Button
                  color="primary"
                  onClick={() => {
                    navigate("/enquete-moniteur");
                  }}
                >
                  Participer maintenant
                </Snackbar.Button>
              </Snackbar>
            )}
        </AdaptiveScreen.Common>,
        <AdaptiveScreen.Desktop key={"cards-desktop"}>
          <div className={css.cards}>
            {signed ? renderSignedDesktop() : renderUnsigned()}
          </div>
        </AdaptiveScreen.Desktop>,
        <AdaptiveScreen.Mobile key={"cards-mobile"}>
          <div className={css.cards}>
            {signed ? renderSignedMobile() : renderUnsigned()}
          </div>
          {hasSecours && (
            <div>
              <Router.Link
                to={`tel:${numeroUrgence}`}
                className={classesLinkUrgences}
              >
                <Badge iconLeft={<E911AvatarIcon />} size="M">
                  Appel secours
                </Badge>
              </Router.Link>
            </div>
          )}
        </AdaptiveScreen.Mobile>,
        <AdaptiveScreen.Common key={"common-login"}>
          {!signed && (
            <div className={css.connexion}>
              <div className={css.hint}>
                Connectez-vous pour accéder à toutes vos fonctionnalités Carnet
                Rouge
              </div>
              <Button
                title="Connexion"
                tag="a"
                href="/"
                onClick={handleSignin}
                className={css.button}
              >
                Connexion
              </Button>
            </div>
          )}
          {toast && (
            <Toast seconds={2.5} variant="warning">
              Service momentanément indisponible
            </Toast>
          )}
        </AdaptiveScreen.Common>,
      ]}
    </AdaptiveScreen>
  );
}

function collectPlannings(navigations?: NavigationNode[]): NavigationNode[] {
  if (!navigations) return [];
  const plannings: NavigationNode[] = [];
  for (const node of navigations) {
    const path = node.path;
    if (isPlanningPath(path)) {
      plannings.push(node);
    }
    if (node.children) {
      plannings.push(...collectPlannings(node.children));
    }
  }
  return plannings;
}
function collectProshops(navigations?: NavigationNode[]): NavigationNode[] {
  if (!navigations) return [];
  const proshops: NavigationNode[] = [];
  for (const node of navigations) {
    const path = node.path;
    if (isProshopPath(path)) {
      proshops.push(node);
    }
    if (node.children) {
      proshops.push(...collectProshops(node.children));
    }
  }
  return proshops;
}

function isPlanningPath(path?: string) {
  if (!path) return false;
  return (
    path.includes("/EsfWebMobile/Planning") ||
    path.includes("/EsfWebMobile/Authentification/Auth") ||
    path.includes("/EsfWeb/PosteMoniteur/Planning") ||
    path.includes("/EsfWebPlanningParticulier/PPLogin.aspx")
  );
}
function isWebPlanningPath(path?: string) {
  if (!path) return false;
  return (
    path.includes("/EsfWeb/PosteMoniteur/Planning") ||
    path.includes("/EsfWebPlanningParticulier/PPLogin.aspx")
  );
}
function isProshopPath(path?: string) {
  if (!path) return false;
  return (
    path !== undefined &&
    (path.startsWith("/https://moniteurs.esfproshop.com") ||
      path.startsWith("/https://ecoles.esfproshop.com"))
  );
}
